<template>
  <DefaultLayout :withHeader="true" :withFooter="true">
    <div class="container mb-5">
      <h1>{{ $t('imprint.title') }}</h1>
      <p>{{ $t('imprint.description') }}</p>
      <div>
        <p>
          <strong>{{ $t('imprint.company') }}</strong><br>
          {{ $t('imprint.websiteLabel') }}: <a :href="$t('imprint.websiteUrl')">{{ $t('imprint.websiteUrl') }}</a><br>
          {{ $t('imprint.emailLabel') }}: info@pana-rent-car.com<br>
          {{ $t('imprint.service') }}<br>
          {{ $t('imprint.privacyPolicyLabel') }}: <a :href="$t('imprint.privacyPolicyUrl')">{{ $t('imprint.privacyPolicyUrl') }}</a><br>
          {{ $t('imprint.date') }}
        </p>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layout/DefaultLayout.vue';

export default {
  name: 'ImprintView',
  components: {
    DefaultLayout,
  },
};
</script>
