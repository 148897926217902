<template>
  <div id="standard-hero" class="my-5" :style="backgroundStyle">
    <div :class="['standard-hero-wrapper', textAlignClass]">
      <div v-if="textBackgroundColor" :style="{ backgroundColor: textBackgroundColor }" class="text-container p-3">
        <h1 v-if="title">{{ title }}</h1>
        <p v-if="description">{{ description }}</p>
      </div>
      <div v-else>
        <h1 v-if="title">{{ title }}</h1>
        <p v-if="description">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandardHero',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    backgroundImage: {
      type: String,
      required: true,
    },
    textBackgroundColor: {
      type: String,
      required: false,
    },
    textAlign: {
      type: String,
      default: 'left',
      validator: value => ['left', 'right'].includes(value),
    },
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.resolveBackgroundImage()})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover',
      };
    },
    textAlignClass() {
      return this.textAlign === 'right' ? 'text-right' : 'text-left';
    }
  },
  methods: {
    resolveBackgroundImage() {
      try {
        return require(`@/assets/images/${this.backgroundImage}`);
      } catch (e) {
        console.error('Image not found:', this.backgroundImage);
        return ''; // Return a fallback image path or an empty string
      }
    }
  }
};
</script>

<style scoped>
#standard-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 16px;
  width: 100%;
  height: 430px;
}

.standard-hero-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.standard-hero-wrapper.text-left {
  align-items: flex-start;
  text-align: left;
}

.standard-hero-wrapper.text-right {
  align-items: flex-end;
  text-align: right;
}

.standard-hero-wrapper p {
  text-align: start;
}

.text-container {
  border-radius: 8px;
}

.standard-hero-wrapper h1 {
  font-size: 52px;
  color: #fff;
  transition: font-size .7s;
}

.standard-hero-wrapper p {
  font-size: 22px;
  color: #fff;
  max-width: 400px;
  margin-top: 16px;
  line-height: 1.1em;
}

@media only screen and (min-width: 570px) {
  .standard-hero-wrapper h1 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 700px) {
  .standard-hero-wrapper h1 {
    font-size: 72px;
  }
}
</style>
