<template>
  <DefaultLayout :withHeader="true" :withFooter="true">
    <div class="container mb-5">
      <h1>{{ $t('privacy.title') }}</h1>
      <p>{{ $t('privacy.description') }}</p>
      <div v-html="$t('privacy.content')"></div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layout/DefaultLayout.vue';

export default {
  components: {
    DefaultLayout,
  },
  name: 'PrivacyView',
};
</script>
