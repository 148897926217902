<template>
  <div class="parallax-container" :style="{
      backgroundImage: `url(${resolveBackgroundImage()})`,
      backgroundAttachment: isMobile ? 'scroll' : 'fixed'
    }">
    <div class="parallax-content">
      <slot></slot> <!-- Platz für zusätzlichen Inhalt -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParallaxHero',
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    speed: {
      type: Number,
      default: 0.5 // Geschwindigkeit des Parallax-Effekts
    }
  },
  data() {
    return {
      isMobile: false
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Kleinere Gerätekennung
    // window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    // handleScroll() {
    //   if (this.isMobile) return; // Kein Parallax auf mobilen Geräten
    //   const parallaxContainer = this.$el;
    //   const containerHeight = parallaxContainer.offsetHeight;
    //   const scrolled = window.scrollY;
    //   const offset = parallaxContainer.getBoundingClientRect().top;
    //   const rate = Math.min(scrolled - offset, containerHeight) * this.speed;
    //
    //   parallaxContainer.style.backgroundPositionY = `${rate}px`;
    // },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    resolveBackgroundImage() {
      try {
        return require(`@/assets/images/${this.backgroundImage}`);
      } catch (e) {
        console.error('Image not found:', this.backgroundImage);
        return '';
      }
    }
  }
};
</script>

<style scoped>
.parallax-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-position 0.5s ease;
}

.parallax-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
