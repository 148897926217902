<template>
  <div>
    <LoadingScreen ref="loadingScreen" />
    <Header v-if="withHeader" />
    <main>
      <div>
        <slot></slot>
      </div>
    </main>
    <Footer v-if="withFooter" />
  </div>
</template>

<script>
import Header from '../components/TheHeader.vue';
import Footer from '../components/TheFooter.vue';
import LoadingScreen from '../components/LoadingScreen.vue';
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";

export default {
  components: {
    LoadingScreen,
    Header,
    Footer,
  },
  props: {
    withHeader: {
      type: Boolean,
      default: true,
    },
    withFooter: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const loadingScreen = ref(null);
    const router = useRouter();

    const hideLoader = () => {
      if (loadingScreen.value) loadingScreen.value.hideLoader();
    };


    const checkImagesLoaded = () => {
      const images = document.images;
      let loaded = true;
      let retryCount = 0;
      const maxRetries = 10;

      const check = () => {
        loaded = true; // Reset loaded status for this check cycle
        for (let i = 0; i < images.length; i++) {
          if (!images[i].complete || images[i].naturalWidth === 0) {
            loaded = false;
            break;
          }
        }

        if (loaded || retryCount >= maxRetries) {
          hideLoader();
        } else {
          retryCount++;
          setTimeout(check, 100);
        }
      };

      check();
    };
    onMounted(() => {
      checkImagesLoaded(); // Initial check when the component is mounted

      router.beforeEach((to, from, next) => {
        if (loadingScreen.value) loadingScreen.value.isLoaded = false;
        next();
      });

      router.afterEach(() => {
        checkImagesLoaded(); // Check again after each route change
      });
    });

    return {
      loadingScreen
    };
  }
};
</script>

<style>
@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../assets/fonts/raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  scroll-behavior: auto;
}

body {
  font-family: 'Raleway', sans-serif;
}
</style>
