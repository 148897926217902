<template>
  <DefaultLayout :withHeader="true" :withFooter="true">
    <div class="container">
      <StandardHero
          :title="$t('banner.contact.title')"
          :description="$t('banner.contact.description')"
          backgroundImage="Thessaloniki_00.jpg"
          textBackgroundColor="#4eedfe"
          text-align="right"
      />
      <h1>{{ $t('contact.title') }}</h1>
    </div>
    <ContactSectionNew />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layout/DefaultLayout.vue';
import StandardHero from "@/components/StandardHero.vue";
import ContactSectionNew from "@/components/ContactSectionNew.vue";

export default {
  components: {
    ContactSectionNew,
    StandardHero,
    DefaultLayout,
  },
};
</script>
