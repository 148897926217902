<template>
  <section class="column-background-block bg-light mb-5 py-5">
    <div class="container">
      <div class="row l-spacing justify-content-center">
        <div class="col-11 col-lg-12 mb-5">
          <h2 class="promo-title text-center">{{ $t('home.chooseUs.title') }}</h2>
        </div>
        <div class="col-11 col-lg-4  mb-5 mb-lg-0 d-flex flex-column text-center align-items-center">
          <div class="icon-stack mb-5">
            <img src="@/assets/icons/cars.svg" alt="Armchair Icon" />
          </div>
          <p class="fw-bold">{{ $t('home.chooseUs.item1') }}</p>
          <div class="button-holder">
<!--            <a href="https://tatrck.com/h/0Hu30uVO0aPZ?url=https%3A%2F%2Fgreenmotion.com%2Fenvironmental-policy" class="btn btn-primary btn-rounded mt-auto w-fit-content">Environmental Policy</a>-->
          </div>
        </div>
        <div class="col-11 col-lg-4 mb-5 mb-lg-0 d-flex flex-column text-center align-items-center">
          <div class="icon-stack mb-5">
            <img src="../assets/icons/compass.svg" alt="Tree Icon" />
          </div>
          <p class="fw-bold">{{ $t('home.chooseUs.item2') }}</p>
          <div class="button-holder">
          </div>
        </div>
        <div class="col-11 col-lg-4 mb-5 mb-lg-0 d-flex flex-column text-center align-items-center">
          <div class="icon-stack mb-5">
            <img src="@/assets/icons/armchair.svg" alt="Cars Icon" />
          </div>
          <p class="fw-bold">{{ $t('home.chooseUs.item3') }}</p>
          <div class="button-holder">
          </div>
        </div>
        <div class="col-12 mt-5 d-flex justify-content-center">
          <router-link to="/car-rental" class="btn btn-lg btn-outline-dark btn-rounded mt-auto w-fit-content">{{ $t('home.chooseUs.btnText') }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ChooseUs',
};
</script>

<style scoped>
.icon-stack img {
  width: 70px;
  height: 70px;
  fill: #01375e;
}

@media (min-width: 768px) {
  .feature-box {
    border-radius: 0;
  }
}
</style>
