<template>
  <div class="contact-section py-5" id="contactSection">
    <div class="container">
      <h2 class="text-center mb-4">{{ $t('contact.title') }}</h2>
      <div class="row">
        <div class="col-md-6">
          <div id="map" class="map-container"></div>
        </div>
        <div class="col-md-6">
          <h3>{{ $t('contact.emailTitle') }}</h3>
          <p class="mb-2">
            <a :href="'mailto:info@pana-rent-car.com'" class="link-dark">info@pana-rent-car.com</a>
          </p>
          <p>
            <a :href="'mailto:panarentcar24@gmail.com'" class="link-dark">panarentcar24@gmail.com</a>
          </p>
          <h3>{{ $t('contact.phoneTitle') }}</h3>
          <div class="d-flex">
            <p class="phone-text">{{ $t('contact.phoneText1') }}</p>
            <img src="@/assets/flags/gb.svg" alt="Country flag" class="flag mx-2">
            <img src="@/assets/flags/gr.svg" alt="Country flag" class="flag">
          </div>
          <div class="d-flex">
            <p class="phone-text">{{ $t('contact.phoneText2') }}</p>
            <img src="@/assets/flags/de.svg" alt="Country flag" class="flag mx-2">
            <img src="@/assets/flags/gr.svg" alt="Country flag" class="flag">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

export default {
  name: 'ContactSectionNew',
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    this.$nextTick(this.initMap);
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
  methods: {
    initMap() {
      if (this.map) return;

      this.map = L.map('map').setView([40.6401, 22.9444], 11);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
    }
  }
};
</script>

<style scoped>
.contact-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.map-container {
  height: 400px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
}

.contact-section h2, .contact-section h3 {
  color: #2b384a;
}

.contact-section p {
  color: #555;
}

.flag {
  width: 30px;
  height: 20px;
}

.phone-text {
  min-width: 140px;
}
</style>
