<template>
  <DefaultLayout :withHeader="true" :withFooter="true">
    <TheSlider />
<!--    <h1>{{ t('home.title') }}</h1>-->
<!--    <p>Welcome to the homepage!</p>-->
    <FeatureSection />
    <ParallaxHero backgroundImage="Thessaloniki_04.jpg">
      <h2>{{ $t('common.enjoyThessaloniki')}}</h2>
    </ParallaxHero>
    <ChooseUs />
    <div class="container mb-5">
      <StandardHero
          :title="$t('banner.home.title')"
          :description="$t('banner.home.description')"
          backgroundImage="Thessaloniki_05.jpg"
          text-align="right"
      />
    </div>
    <div class="container">
      <PopularPlaces />
    </div>
    <ContactSectionNew />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheSlider from "@/components/TheSlider.vue";
import FeatureSection from "@/components/FeatureSection.vue";
import ChooseUs from "@/components/ChooseUs.vue";
import StandardHero from "@/components/StandardHero.vue";
import ParallaxHero from "@/components/ParallaxHero.vue";
import PopularPlaces from "@/components/PopularPlaces.vue";
import ContactSectionNew from "@/components/ContactSectionNew.vue";

export default {
  name: 'HomeView',
  components: {
    ContactSectionNew,
    PopularPlaces,
    ParallaxHero,
    StandardHero,
    ChooseUs,
    FeatureSection,
    DefaultLayout,
    TheSlider
  }
}
</script>
