<template>
  <section class="popular-places">
    <h3>{{ $t('popularPlaces.title')}}</h3>
    <p>{{ $t('popularPlaces.description')}}</p>
    <div class="row">
      <article
          class="col-12 col-lg-6 mb-5 text-center"
          v-for="place in places"
          :key="place.id"
          @click="openModal(place)"
          @keydown.enter="openModal(place)"
          @keydown.space.prevent="openModal(place)"
          tabindex="0"
          role="button"
          :aria-label="$t(`popularPlaces.places.${place.id}.title`)">
        <div class="card shine-overlay mb-4">
          <div class="shine"></div>
          <div class="cta position-relative" style="height: 250px;">
            <picture class="cta position-absolute d-flex justify-content-center w-100 overflow-hidden">
              <img :src="getImageSrc(place.imageSrc)" :alt="place.title" class="img-fluid" height="250px" loading="lazy">
            </picture>
            <div class="d-flex flex-column h-100 w-100 overlay position-absolute">
              <h3 class="text-center my-auto text-white px-5">{{ $t(`popularPlaces.places.${place.id}.title`) }}</h3>
            </div>
          </div>
        </div>
      </article>
    </div>
    <PlaceModal v-if="selectedPlace" :place="selectedPlace" @close="closeModal" />
  </section>
</template>

<script>
import PlaceModal from "@/components/PlaceModal.vue";
import {getImageSrc} from "@/helper/getImageSrc";
import { Modal } from 'bootstrap';

export default {
  components: {
    PlaceModal
  },
  data() {
    return {
      places: [
        {
          id: 'anoPoli',
          title: 'Ano Poli (Upper Town)',
          imageSrc: 'Thessaloniki_Ana_Poli.jpg',
          url: 'https://en.wikipedia.org/wiki/Ano_Poli_(Thessaloniki)'
        },
        {
          id: 'whiteTower',
          title: 'White Tower',
          imageSrc: 'Thessaloniki_02.jpg',
          url: 'https://en.wikipedia.org/wiki/Ano_Poli_(Thessaloniki)'
        },
        {
          id: 'beaches',
          title: 'Beaches',
          imageSrc: 'Thessaloniki_06.jpg',
          url: 'https://en.wikipedia.org/wiki/Ano_Poli_(Thessaloniki)'
        },
        {
          id: 'archOfGalerius',
          title: 'Arch of Galerius',
          imageSrc: 'Thessaloniki_Galerius_Arch.jpg',
          url: 'https://en.wikipedia.org/wiki/Ano_Poli_(Thessaloniki)'
        },
      ],
      selectedPlace: null
    };
  },
  methods: {
    getImageSrc,
    openModal(place) {
      this.selectedPlace = place;
      this.$nextTick(() => {
        const modalElement = document.getElementById('placeModal');
        if (modalElement) {
          const modalInstance = new Modal(modalElement);
          modalInstance.show();
        }
      });
    },
    closeModal() {
      this.selectedPlace = null;
    }
  }
};
</script>
<style scoped>
.popular-places .card {
  border-radius: 7px;
  box-shadow: 5px 3px 6px #00000029;
  background-color: #fff;
  border: 0;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.popular-places .shine-overlay:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.popular-places .shine {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transform: skew(30deg);
  animation: shine 0.45s linear 1;
}

.popular-places .shine-overlay:hover .shine {
  display: block;
}

@keyframes shine {
  0% {
    left: -50%;
    opacity: 0;
  }
  50% {
    left: 25%;
    opacity: 0.5;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

.popular-places .card-bg {
  opacity: 0.8;
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}

.popular-places .card-content {
  position: relative;
  z-index: 2;
  padding: 20px;
}
</style>

