import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from "@/views/AboutView.vue";
import ContactView from "@/views/ContactView.vue";
import ImprintView from "@/views/ImprintView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import {nextTick} from "vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'home.pageTitle'
    },
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: 'about.pageTitle'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'contact.pageTitle'
    },
    component: ContactView
  },
  {
    path: '/car-rental',
    name: 'car-rental',
    component: () => import(/* webpackChunkName: "about" */ '../views/VehiclesView.vue'),
  },
  {
    path: '/imprint',
    name: 'imprint',
    meta: {
      title: 'imprint.pageTitle'
    },
    component: ImprintView
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: {
      title: 'privacy.pageTitle'
    },
    component: PrivacyView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      nextTick(() => {
        // Gespeicherte Position beim Vor-/Zurück-Button wiederherstellen
        if (savedPosition) {
          resolve(savedPosition);
        }
        // Bei Hash-Navigation zum Hash-Element scrollen
        else if (to.hash) {
          resolve({
            el: to.hash,
            behavior: 'smooth',
          });
        }
        // Immer sofort nach oben scrollen bei einem Seitenwechsel
        else {
          resolve({ left: 0, top: 0 });
        }
      });
    });
  }
});

export default router
